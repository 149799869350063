import React from 'react'
import './App.css'
import { StarRatingInput, css } from 'react-star-rating-input'
import insertCss from 'insert-css'

export default class Container extends React.Component {
  
  constructor(props) {
      super(props)
      this.url = window.pagerankz.dir + 'pagerankz/pageRankRes.php';
      this.handleChange = this.handleChange.bind(this)
      this.state = {
        data: {"ratingValue":0,"bestRating":0,"worstRating":0,"ratingCount":0,"name":''},
      }
  }

  componentDidMount() {
    fetch(this.url + '?webrequest=true')
      .then(res => res.json())
      .then(json => this.setState({ data: JSON.parse(json) }))
  }

  handleChange(value: number): void {
    var response = null;
    var _self = this;
    fetch(this.url, {
      method: 'post', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // redirect: 'follow', // manual, *follow, error
      // referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({'vote': value, 'webrequest': true}), // body data type must match "Content-Type" header
    })
    .then(response => response.json()
      .then(function(value) {
        _self.setState({ data: JSON.parse(value) });
      })
    );
  }
  
  render() {
    return (
      <div className="App">
        <h2>{this.state.data.name}</h2>
        <div className="pr-r-box">
          <StarRatingInput
                    size={5}
                    value={Math.round(this.state.data.ratingValue)}
                    onChange={this.handleChange} /><br />

          <div>
            <br />
            <div>
              <strong>Page rating:</strong> <span>{this.state.data.ratingValue > 0 ? 
              Number(this.state.data.ratingValue).toFixed(2) : 0}</span> out of <span>{this.state.data.bestRating}</span> <br />
              <strong>Ratings:</strong> <span>{this.state.data.ratingCount}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface State {
  value: number
}

insertCss(css)